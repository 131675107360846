import React from "react";
import { Text } from "~/components/text-node";

export default function Notice() {
  return (
    <nav className="w-full px-4 py-3 text-sm text-center text-white bg-black border-b border-gray-700 notice center md:text-sm lg:px-12 lg:text-base">
      <Text as="span" at="notice.home" />
    </nav>
  );
}
