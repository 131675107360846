import Script from "next/script";
import BaseLayout from "~/components/base-layout";
import { HeadContent } from "~/components/head";
import { Page } from "~/components/page";
import { renderSegmentSnippet } from "~/lib/renderSegmentSnippet";

const Wrapper = ({ children }) => {
  return (
    <>
      <HeadContent />
      {/* Inject the Segment snippet into the <head> of the document  */}
      {process.env.NEXT_PUBLIC_LADDER_QUIZ_SEGMENT_WRITE_KEY ? (
        <Script
          id="segment"
          dangerouslySetInnerHTML={{
            __html: renderSegmentSnippet(
              process.env.NEXT_PUBLIC_LADDER_QUIZ_SEGMENT_WRITE_KEY
            ),
          }}
        />
      ) : null}
      {children}
    </>
  );
};

const Layout = ({ children, ...props }) => {
  return (
    <Wrapper>
      <BaseLayout {...props}>
        <Page>{children}</Page>
      </BaseLayout>
    </Wrapper>
  );
};

export default Layout;
