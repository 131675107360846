import React, { useEffect, useState } from "react";
import { useToggle } from "react-use";
import { createContainer } from "unstated-next";

const initialTitle =
  "This feature is available exclusively on the Ladder iPhone app";
const initialDescription =
  "Discover a workout plan for every day that's easy to follow, can be done from anywhere, and is new each week.";

const useContainer = () => {
  const [appLockedPromptOpen, setAppLockedPromptOpen] = React.useState(false);
  const [showQRModal, toggleShowQRModal] = useToggle(false);
  const [title, setTitle] = useState(initialTitle);
  const [description, setDescription] = useState(initialDescription);

  const handleClose = () => {
    setAppLockedPromptOpen(false);
    setTitle(initialTitle);
    setDescription(initialDescription);
  };

  useEffect(() => {
    if (!appLockedPromptOpen) {
      setTimeout(() => {
        setTitle(initialTitle);
        setDescription(initialDescription);
      }, 500);
    }
  }, [appLockedPromptOpen]);

  return {
    showQRModal,
    toggleShowQRModal,
    appLockedPromptOpen,
    setAppLockedPromptOpen,
    title,
    setTitle,
    description,
    setDescription,
    handleClose,
  };
};

const { useContainer: useModals, Provider: ModalsProvider } =
  createContainer(useContainer);

export { useModals, ModalsProvider };
