import { useIsDesktop } from "~/lib/use-breakpoint";
import { useModals } from "../modals/container";

import { captureEvent } from "~/lib/captureEvent";
import { updateUTMParams } from "~/lib/misc";

export const useHandleClickCTA = (
  launchProgramID,
  CTALabel = "SEE MORE WORKOUTS"
) => {
  const isDesktop = useIsDesktop();
  const { toggleShowQRModal } = useModals();

  const baseUrl = new URL(
    "https://ladder.page.link?ibi=com.ladder.bootcamp&isi=1502936453&ius=ladderteams"
  );
  const baseDeepLink = new URL(
    `https://joinladder.com${
      launchProgramID ? `?launchProgramID=${launchProgramID}` : ""
    }`
  );

  const link = updateUTMParams(baseDeepLink, "_appdirect");

  const deepLink = new URL(baseUrl);
  deepLink.searchParams.set("link", link);

  const href = isDesktop
    ? updateUTMParams(`https://offers.joinladder.com/survey`, "_homepagesurvey")
    : deepLink;

  const handleClickCta = () => {
    captureEvent({
      category: "workout",
      action: "workout_cta_clicked",
      label: CTALabel,
      value: isDesktop ? "Show QR Modal" : href?.toString?.(),
    });

    if (isDesktop) {
      toggleShowQRModal(true);
    } else {
      window.open(href, "_blank");
    }
  };

  return { handleClickCta, deepLink, href };
};
