import { useRouter } from "next/router";
import { useEffect } from "react";
import { Footer } from "~/components/footer";
import { HeadContent } from "~/components/head";
import { HomeComponent } from "~/components/home";
import OptimizeLayout from "~/components/layout";
import { ModalsProvider, useModals } from "~/components/modals/container";
import { DesktopQRModal } from "~/components/modals/desktop-qr-modal";
import Nav from "~/components/nav";
import Notice from "~/components/notice/home";
import { ExperimentProvider } from "~/state-containers/experiment";
import { isDev } from "../helpers/misc";

const Content = () => {
  const { query, isReady } = useRouter();
  const { toggleShowQRModal } = useModals();

  useEffect(() => {
    if (!isReady) return;
    if (!query.showAppDownloadQR) return;

    toggleShowQRModal();
  }, [query, isReady, toggleShowQRModal]);

  return (
    <>
      <Notice />
      <Nav showWWOD />
      <div className="h-full max-w-full overflow-hidden">
        <HomeComponent />
        <Footer />
      </div>
    </>
  );
};

const defaultLink = `https://ladder.page.link/?ibi=com.ladder.bootcamp&isi=1502936453&ius=ladderteams&link=https://${
  isDev ? "dev." : ""
}joinladder.com`;

export default function Home() {
  return (
    <>
      <HeadContent title="Ladder | Your Daily Workout Plan" />
      <ExperimentProvider>
        <ModalsProvider>
          <DesktopQRModal
            title="Download the app!"
            description="Scan the QR code start your workout"
            deepLink={defaultLink}
          />
          <Content />
        </ModalsProvider>
      </ExperimentProvider>
    </>
  );
}

Home.Layout = OptimizeLayout;

// export async function getStaticPaths() {
//   const experiment = getCurrentExperiment()

//   return {
//     paths: experiment.variants.map((v) => ({
//       params: { variant: `${experiment.id}.${v.id}` },
//     })),
//     fallback: false,
//   }
// }
