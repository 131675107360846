import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef } from "react";
import { QRCode } from "~/components/qr-code/QRCode";
import { useUrlShortener } from "~/hooks/useUrlShortener";
import { useHandleClickCTA } from "../workout/misc";
import { useModals } from "./container";

export const DesktopQRModal = ({
  launchProgramID,
  title = "GET THE LADDER APP",
  description = "Scan to download the Ladder iOS app!",
}) => {
  const cancelButtonRef = useRef(null);
  const { showQRModal, toggleShowQRModal } = useModals();
  const { deepLink } = useHandleClickCTA(launchProgramID, "GET THE APP");

  const { data, error, isLoading } = useUrlShortener(deepLink);
  const shortUrl = data?.result?.link;

  return (
    <Transition.Root show={showQRModal} as={Fragment}>
      <Dialog
        as="div"
        style={{ zIndex: 100 }}
        className="fixed inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={toggleShowQRModal}
      >
        <div className="items-center justify-center hidden min-h-screen px-4 pt-4 pb-20 text-center sm:flex sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-white/50 backdrop-blur-sm" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block px-4 pt-5 pb-6 overflow-hidden text-left align-bottom transition-all transform border shadow-xl bg-dark-slanted-gradient border-space-200 rounded-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:pt-6 sm:px-12 sm:pb-12">
              <h1 className="py-6 mb-2 text-4xl tracking-wide text-center text-white uppercase 2xl:text-5xl font-display">
                {title}
              </h1>
              <div
                className="flex items-center justify-center p-6 mx-auto transition-transform duration-150 ease-in-out bg-white border border-black shadow-2xl rounded-3xl"
                style={{ width: 330, height: 330 }}
              >
                {error ? (
                  "Something went wrong. Please refresh and try again."
                ) : shortUrl ? (
                  <QRCode size={280} logoSize={0} value={shortUrl} />
                ) : (
                  "Loading..."
                )}
              </div>
              <div className="pt-3 pb-3 space-x-2 text-xl font-bold text-center 2xl:text-2xl text-lemon">
                {description}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
