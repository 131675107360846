import { useQuery } from "react-query";
import { fetcher } from "~/lib/misc";

export const getShortUrl = (longUrl) => async () => {
  if (!longUrl) return null;

  const response = await fetcher("/api/shorten-url", {
    method: "POST",
    body: JSON.stringify({
      longUrl,
    }),
  });

  return response;
};

export const useUrlShortener = (longUrl, initialData) => {
  const { data, error, isLoading } = useQuery(
    longUrl && `/api/shorten-url/${longUrl}`,
    getShortUrl(longUrl),
    {
      initialData,
    }
  );

  return {
    data,
    error,
    isLoading,
  };
};
